import React from 'react';
import { StaticQuery, graphql, navigate } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  breakpointQuery,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  fluidFontSize,
  visuallyHidden,
} from '../utilities';
import Animation from '../components/Animation';
import { ButtonInput } from '../components/FormFields';
import Container from '../components/Container';
import { GetInTouch, FindUs } from '../components/footer/ContactInformation';

const sharedStyles = css`
  ${fontSize(14)};
  color: ${standardColours.white};
  border: none;
  background: transparent;
  border-bottom: 1px solid ${standardColours.grey};

  ${breakpointQuery.large`
    height: 44px;
    ${fontSize(15)};
  `}

  &::-webkit-input-placeholder {
    color: ${standardColours.white};
  }

  &:-ms-input-placeholder {
    color: ${standardColours.white};
  }

  &::placeholder {
    opacity: 1;
    color: ${standardColours.white};
  }
`;

const InformationWrap = styled.div`
  margin-top: 80px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;

  ${breakpointQuery.tiny`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${breakpointQuery.large`
    margin-top: 0;
    grid-template-columns: 1fr;
    grid-gap: 50px;
  `}
`;

const Wrap = styled.div`
  margin-top: 40px;

  ${breakpointQuery.large`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 140px;
    margin-top: 60px;
  `}

  ${breakpointQuery.xlarge`
    grid-gap: 190px;
  `}
`;

const UpperLayer = styled.div`
  ${breakpointQuery.large`
    position: relative;
    z-index: 10;
  `}
`;

const LowerLayer = styled.div`
  ${breakpointQuery.large`
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background: ${brandColours.primary};
    z-index: 1;
  `}
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;

  ${breakpointQuery.large`
    grid-gap: 50px;
  `}
`;

const Radio = styled.span`
  position: relative;
  min-height: 60px;
  width: 30vw;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -1px;
  padding: 10px;
  color: ${standardColours.white};
  border: 1px solid ${standardColours.grey};
  cursor: pointer;
  ${fontSize(14)}

  ${breakpointQuery.large`
    width: 162px;
    padding: 0;
    line-height: 58px;
  `}

  input:checked ~ & {
    z-index: 2;
    border-color: ${brandColours.tertiary};
  }
`;

const RadioButtons = styled.div`
  display: flex;
  text-align: center;
  margin-bottom: 30px;
`;

const Label = styled.label`
  display: flex;

  &:first-child {
    ${Radio} {
      margin-left: 0px;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }

  &:last-child {
    ${Radio} {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  input {
    ${visuallyHidden};
  }

  ${({ hide }) => {
    if (hide) {
      return `
        ${visuallyHidden()}
      `;
    }
  }}
`;

const InformationSection = styled.div`
  color: ${standardColours.white};

  h3 {
    font-weight: ${fontWeights.light};

    ${breakpointQuery.large`
      ${fontSize(26)}
      line-height: 1.6;
    `}

    ${breakpointQuery.xlarge`
      ${fontSize(31)}
    `}
  }

  p {
    font-weight: ${fontWeights.feather};

    ${breakpointQuery.large`
      ${fontSize(18)}
      line-height: 1.7;
    `}

    ${breakpointQuery.xlarge`
      margin-top: 25px;
      ${fontSize(22)}
    `}
  }
`;

const FullBackground = styled.div`
  padding-top: 60px;
  padding-bottom: 80px;
  background: ${standardColours.darkestGrey};

  ${breakpointQuery.large`
    position: relative;
    padding-top: 80px;
    padding-bottom: 140px;
  `}

  ${breakpointQuery.xlarge`
    padding-top: 110px;
    padding-bottom: 210px;
  `}
`;

const Input = styled.input`
  height: 38px;
  ${sharedStyles};
`;

const ButtonWrap = styled.div`
  margin-top: 20px;

  ${breakpointQuery.large`
    margin-top: 0;
  `}
`;

const FormButton = styled(ButtonInput)`
  ${breakpointQuery.large`
    width: auto;
    padding-left: 30px;
    padding-right: 30px;
  `}
`;

const TextArea = styled.textarea`
  height: 120px;
  resize: vertical;
  ${sharedStyles};

  ${breakpointQuery.large`
    height: 180px;
  `}
`;

const Heading = styled.h1`
  margin-bottom: 40px;
  ${fluidFontSize(
    '30px',
    '52px',
    breakpointSizes.smedium,
    breakpointSizes.large
  )}
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: ${standardColours.white};

  ${breakpointQuery.large`
    max-width: 380px;
    margin-bottom: 50px;
    line-height: 1.4;
  `}
`;

const Text = styled.p`
  display: inline;
  margin-left: 8px;
  color: ${standardColours.white};
  ${fontSize(13)}
  font-weight: ${fontWeights.light};
  line-height: 1.7;

  a {
    color: ${brandColours.tertiary};
  }
`;

const inputProps = (radioValue, radioNumber, value) => {
  const props = {
    type: 'radio',
    name: 'type',
    value,
  };

  if (radioValue === radioNumber) {
    props.defaultChecked = true;
  }

  return props;
};

const Contact = ({
  location,
  data: {
    datoCmsSitewide: { address, phoneNumber, email, teamsCallUrl },
    datoCmsContact: { emailExclusionItems },
  },
}) => {
  const contactType =
    location.state && location.state.contactType
      ? location.state.contactType
      : 1;

  const hubspotutk =
    typeof document !== 'undefined'
      ? document.cookie.replace(
          /(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/,
          '$1'
        )
      : '';

  const handleSubmit = e => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);

    const emailAddress = formData.get('email');

    if (
      !emailExclusionItems.find(({ email }) => emailAddress.includes(email))
    ) {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => {
          navigate('/contact-thanks/');
        })
        .catch(error => console.log(error));
    }
  };

  return (
    <Layout>
      <HelmetDatoCms title="Contact | Barcanet" />
      <main>
        <FullBackground>
          <UpperLayer>
            <Container>
              <Wrap>
                <Animation>
                  <Heading>What can we help you with?</Heading>
                  <form
                    id="contact-form"
                    name="contact"
                    method="POST"
                    data-netlify="true"
                    netlify-honeypot="bot-field"
                    onSubmit={e => handleSubmit(e)}
                  >
                    <input type="hidden" name="form-name" value="contact" />
                    <RadioButtons>
                      <Label>
                        <input {...inputProps(contactType, 1, 'Sales')} />
                        <Radio>
                          <span>Sales</span>
                        </Radio>
                      </Label>
                      <Label>
                        <input
                          {...inputProps(contactType, 2, 'Customer Support')}
                        />
                        <Radio>
                          <span>Customer Support</span>
                        </Radio>
                      </Label>
                      <Label>
                        <input {...inputProps(contactType, 3, 'Demo')} />
                        <Radio>
                          <span>Demo</span>
                        </Radio>
                      </Label>
                    </RadioButtons>
                    <FormGrid>
                      <Input
                        type="text"
                        name="first-name"
                        placeholder="First Name"
                        required
                      />
                      <Input
                        type="text"
                        name="last-name"
                        placeholder="Last Name"
                        required
                      />
                      <Input
                        type="text"
                        name="job-title"
                        placeholder="Job Title"
                        required
                      />
                      <Input
                        type="email"
                        name="email"
                        placeholder="Work Email"
                        required
                      />
                      <TextArea name="message" placeholder="Message" />
                      <label>
                        <input
                          type="checkbox"
                          name="marketing-consent"
                          value="true"
                        />
                        <Text>
                          I consent to receiving occasional marketing emails
                          from Barcanet. I can opt out any time. View our{' '}
                          <a href="/privacy-policy/" target="_blank">
                            privacy policy
                          </a>
                          .
                        </Text>
                      </label>
                      <Label hide="true">
                        <Text>Don’t fill this out if you're human</Text>
                        <Input
                          type="text"
                          name="bot-field"
                          placeholder="Don’t fill in"
                        />
                      </Label>
                      <input
                        type="hidden"
                        name="hubspotutk"
                        value={hubspotutk}
                      />
                      <input type="hidden" name="page-name" value="Contact" />
                      <ButtonWrap>
                        <FormButton type="submit" value="Submit" />
                      </ButtonWrap>
                    </FormGrid>
                  </form>
                </Animation>
                <Animation>
                  <InformationWrap>
                    <InformationSection>{FindUs(address)}</InformationSection>
                    <InformationSection>
                      {GetInTouch(phoneNumber, email)}
                      {teamsCallUrl && (
                        <p>
                          <a
                            href={teamsCallUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Book A Teams Call »
                          </a>
                        </p>
                      )}
                    </InformationSection>
                  </InformationWrap>
                </Animation>
              </Wrap>
            </Container>
          </UpperLayer>
          <LowerLayer></LowerLayer>
        </FullBackground>
      </main>
    </Layout>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query ContactQuery {
        datoCmsSitewide {
          address
          phoneNumber
          email
          teamsCallUrl
        }
        datoCmsContact {
          emailExclusionItems {
            email
          }
        }
      }
    `}
    render={data => <Contact data={data} {...props} />}
  />
);
